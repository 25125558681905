import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Accordion from '../components/Accordion/Accordion'
import LoginForm from '../components/LoginForm/LoginForm'
import RegisterForm from '../components/RegisterForm/RegisterForm'

import useAuth from "../hooks/useAuth"

export default function LoginPage( { location } ) {

	const { state: routeState } = location;
	const { isAuthenticated } = useAuth()

	const redirect = !routeState
		? '/account'
		: routeState.redirect === 'account'
		? '/account'
		: `/account/${routeState.redirect}`;

	useEffect( () => {
		if ( isAuthenticated ) {
			navigate( '/account' );
		}
	}, [ isAuthenticated ] );

	return (
		<div className="wrapper">
			<div className="login-page">
				<div className="login-page__header">
					<h1 className="login-page__heading">Sign in to your account</h1>
				</div>
					<div className="login-page__accordion-container">
						<Accordion noBorder={ true } openOnLoad={ true }>
							<div accordionTitle="Returning Customer">
								<LoginForm cssClass="login-page__login-form" redirect={ redirect } />
							</div>
							<div accordionTitle="New Customer">
								<RegisterForm />
							</div>
						</Accordion>
					</div>
				</div>
		</div>
	)
}